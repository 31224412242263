export default EnergyPriceLevels;

function EnergyPriceLevels(vat = 1) {
  let priceLevels = {};
const basePriceLevels = {"low":"0","medium":"110","concerning":"130","high":"200"};

  for (let key in basePriceLevels) {
    priceLevels[key] = Math.round((basePriceLevels[key] * vat) / 10) * 10;
  }

  return priceLevels;
}
